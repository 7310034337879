import { cache } from "@solidjs/router";
import { gql } from "@solid-primitives/graphql";
import { getBuilderContent, getBuilderContentByModel } from "~/utils/builder";

export const getBuilderGlobalData = cache(async () => {
  "use server";
  // ? from previous - caching necessary now?
  // const cache = await getCache("global");
  // if (cache !== null) return cache;

  const response = await fetch(
    `https://cdn.builder.io/api/v3/graphql/${
      import.meta.env.VITE_BUILDER_APIKEY
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        variables: {},
        query: gql`
          query {
            header: symbol(query: { name: "Global Header" }) {
              id
              name
              modelId
              data {
                blocks
              }
            }
            footer: symbol(query: { name: "Global Footer" }) {
              id
              name
              modelId
              data {
                blocks
              }
            }
            announcements(limit: 200) {
              name
              data {
                copy
                buttonLabel
                buttonUrl
                linkAltText
              }
            }
            jobs(options: { includeUnpublished: false }) {
              name
            }
            maintenanceBlocker(options: { includeUnpublished: false }) {
              name
              data {
                active
                title
                description
                richText
              }
            }
            category(limit: 200) {
              name
              data {
                logo
              }
            }
            colour(limit: 200) {
              name
              data {
                colour
                gradient
              }
            }
          }
        `,
      }),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return {
      header: data.header[0],
      footer: data.footer[0],
      jobs: data.jobs.length,
      announcements: data.announcements,
      maintenanceBlocker: data?.maintenanceBlocker?.[0],
      categories: data.category,
      colours: data.colour,
    };
  } else {
    throw new Error("Internal error.");
  }
}, "builder-global");

export const getBuilderPageData = cache(async (path) => {
  return await getBuilderContent(path, "page");
}, "builder-pages");

export const getBuilderAhmEntries = cache(async () => {
  return await getBuilderContentByModel("ahm", 999, 0);
}, "ahm-entries");

export const getBuilderCareerEntries = cache(async () => {
  return await getBuilderContentByModel("jobs", 999, 0);
}, "career-entries");

// const [options] = createResource(async () => {
//   const cache = await getCache("single_page_options");
//   if (cache !== null) return cache;
//   const response = await fetch(
//     `https://cdn.builder.io/api/v3/graphql/${
//       import.meta.env.VITE_BUILDER_APIKEY
//     }`,
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         variables: {},
//         query: gql`
//           query {
//             category(limit: 200) {
//               name
//               data {
//                 logo
//               }
//             }
//             colour(limit: 200) {
//               name
//               data {
//                 colour
//                 gradient
//               }
//             }
//           }
//         `,
//       }),
//     }
//   );
//   const resp = await response.json();
//   const data = { colours: {}, categories: {} };
//   for (const category of resp.data.category) {
//     data.categories[category.name] = category.data.logo;
//   }
//   for (const colour of resp.data.colour) {
//     data.colours[colour.name] = [
//       colour.data.colour,
//       colour.data.gradient || false,
//     ];
//   }
//   setCache("single_page_options", data);
//   return data;
// });
